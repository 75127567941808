import React, { useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';

function ImageMobile(props) {
    const file = props.file;
    const idx = props.idx;
    const handleDelete = props.handleDelete;
  


  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <CancelIcon
        style={{
          position: 'absolute',
          top: '5px', // Adjust as needed
          right: '5px', // Adjust as needed
          cursor: 'pointer',
        }}
        onClick={() => {
          handleDelete(idx);
          
        }}
      />
      <img
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        }}
        src={file}
        alt="Image"
      />
    </div>
  );
}

export default ImageMobile;
