import { useMediaQuery } from "react-responsive";
import MobileTimesheet from "../components/timesheet/mobile/timesheet";
import { useParams } from "react-router-dom";
import { fetchReport } from "../services/fetch";
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';
import { UpdateReport, SaveFiles, UpdateReportStatus } from "../services/fetch";


export function TimesheetPage() {

    const isMobile = useMediaQuery({maxWidth: 767});
    const url_param = useParams();
    const [report, setReport] = useState(null);
    const [load, setLoad] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [totalWorkTime, setTotalWorkTime] = useState(true);

    

    useEffect(() => {
      async function fetchReportData() {
        try {
          const response = await fetchReport(url_param['id']);
          setReport(response);
        } catch (error) {
          console.log(error);
        }
      }
      fetchReportData();
    }, [url_param]);


    const handleUpdateData = (data) => {
      if (data !== undefined) {
        setTotalWorkTime(!totalWorkTime);
        setReport(data);
      }
    }


    const showSuccessMessage = () => {
      setIsSuccessful(true);
  
      // Reset the success state after a certain period (e.g., 3 seconds)
      setTimeout(() => {
        setIsSuccessful(false);
      }, 3500);
    };

    const handleSaveData = () => {
      if (true) {
        setLoad(true);
        const payload = { ...report };
        const response = UpdateReport(url_param['id'], payload)
        .then((response) => {
          setLoad(false);
          showSuccessMessage();
        })
        .catch((error) => {
          setLoad(false)
          console.error(error);
        });
      }
    }

    const handleStatusUpdate = (data) => {
      setLoad(true);
      const response = UpdateReportStatus(url_param['id'], data)
      .then((response) => {
        setLoad(false);
        showSuccessMessage();
      })
      .catch((error) => {
        setLoad(false);
        console.log(error);
      })
    }


    if (!report) {
      return (
        <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItem: 'center'
            }}
          >
          <CircularProgress></CircularProgress>
        </Box>
      )
    } else {
      return (
        <div>
            {isMobile ? <MobileTimesheet report={report} onDataChange={handleUpdateData} onDataSave={handleSaveData} onStatusChange={handleStatusUpdate} load={load} isSuccessful={isSuccessful} totalWorkTime={totalWorkTime}></MobileTimesheet> : <MobileTimesheet report={report} onDataChange={handleUpdateData} onDataSave={handleSaveData} onStatusChange={handleStatusUpdate} load={load} isSuccessful={isSuccessful} totalWorkTime={totalWorkTime}></MobileTimesheet>}
        </div>
      );
    }
  }