


export const contracts_db = [
    {value: '123000', label: '123 000 - Usine #22 St-Marc-des-Carrières'},
    {value: '123004', label: '123 004 - Usine #178 St-Augustin-de-Desmaures'},
    {value: '123005', label: '123 005 - Usine mobile #185'},
    {value: '123006', label: '123 006 - Usine #200 Bécancour'},
    {value: '123007', label: '123 007 - Usine Routek #187 Montréal'},
    {value: '123008', label: '123 008 - Usine #221 St-Lambert-de-Lauzon'},
    {value: '723005', label: '723 005 - Fourniture contrats divers'},
    {value: '823012', label: "823 012 - Garage St-Marc"},
    {value: '823013', label: "823 013 - Garage Pont-Rouge"},
    {value: '823014', label: "823 014 - Garage St-Ubalde"},
    {value: '220207', label: "220 207 - Réfection de la rue des Dériveurs, du Boulevard Gingras et de la piste multifonctionnelle"},
    {value: '220219', label: "220 219 - Stationnement et allées piétonnes rue Hardy"},
    {value: '221201', label: "221 201 - Développement St-Apollinaire 2ème phase"},
    {value: '221207', label: "221 207 - Stabilisation de talus et réfection de ponceaux sur la 138"},
    {value: '221212', label: "221 212 - Réfection de 5 ponceaux incluant tous les travaux connexes sur les routes 367 et 41280"},
    {value: '221213', label: "221 213 - Réfection du boulevard Cloutier, avenues des Octaves et des Sources"},
    {value: '221215', label: "221 215 - Réaménagement de l'intersection de la route de Fossambault et Duchesnay"},
    {value: '221216', label: "221 216 - Développement Le Bourg, phase III"},
    {value: '222217', label: "221 217 - Construction de la rue des Cordiers dans le parc industriel Lauzon, phase II"},
    {value: '222210', label: "222 210 - Développement domiciliaire Saint-Basile-sur-le-parc Phase 3"},
    {value: '222201', label: "222 201 - Prolongement rue des Sables Développement SCJC inc."},
    {value: '222202', label: "222 202 - Bâtiment H Dalcon, Espace Innovation Chauveau"},
    {value: '222206', label: "222 204 - Réfection des infrastructures de la rue Pierre-Georges-Roy"},
    {value: '222207', label: "222 207 - Réfection complète des avenues Châtellenie et des Maires-Gauthier"},
    {value: '222211', label: "222 211 - Travaux de réfection d'une partie de la rue Sainte-Angélique, de la rue Hardy et du stationnement de l'église"},
    {value: '222214', label: "222 214 - Complexe Santé Medway, Quartier Anastasie, Phase 1"},
    {value: '222219', label: "222 219 - Remplacement de 3 ponceaux et travaux de planage/pavage sur la route 367"},
    {value: '222220', label: "222 220 - Développement du Sureau"},
    {value: '222223', label: "222 223 - Grues Guay Travaux ferroviaires"},
    {value: '223201', label: "223 201 - Projet de réparation grue #8 au Port de Québec"},
    {value: '223203', label: "223 203 - Drainage et réfection du chemin des Lacs"},
    {value: '223206', label: "223 206 - Agrandissement de Transport Matte"},
    {value: '223207', label: "223 207 - Reconstruction du seuil 29 et réhabilitation des voies de circulation Golf et Hôtel"},
    {value: '223208', label: "223 208 - Correction de dévers, travaux de planage/pavage et travaux divers sur les routes 367 et 41474"},
    {value: '223212', label: "223 212 - Réfection de la rue De Bernières"},
    {value: '223214', label: "223 214 - Accréditation d'entrepreneurs, Travaux d'entretien"},
    {value: '223215', label: "223 215 - Augmentation de la capacité de l'égout sanitaire de la rue Plante"},
    {value: '223216', label: "223 216 - Aménagement de nouvelles pistes cyclables (PAM220808)"},
    {value: '223217', label: "223 217 - Travaux de réfection, Rues Saint-Victor et Saint-Charles"},
    {value: '223218', label: "223 218 - Travaux d'aménagement aire d'entreposage quai 108- Terminal Beauport"},
    {value: '223219', label: "223 219 - Travaux préparatoires, Canadian Tire"},
    {value: '223220', label: "223 220 - Stationnement Irving, Midland Transport"},
    {value: '223221', label: "223 221 - Réfection des infrastructures souterraines et aménagement de la surface de la 18e Rue (PSO185434 et PAM210107)"},
    {value: '223222', label: "223 222 - Aménagement du terrain de Décor Plus"},
    {value: '223223', label: "223 223 - Remplacement de la conduite de refoulement d'égout sanitaire Établissement de Donnacona"},
    {value: '223224', label: "223 224 - Déblai et pavage stationnement Motel sous les Charmilles"},
    {value: '322028', label: "322 028 - Développement Beau-Rivage, Phase 3"},
    {value: '322082', label: "322 082 - Routes 354, 367 et 40621"},
    {value: '322090', label: "322 090 - Réfection de 5 ponceaux sur les rtes 138, 363 et 365"},
    {value: '322115', label: "322 115 - Dév. domiciliaire, Boisé Nature 3R"},
    {value: '323004', label: "323 004 - Contrats de pavage divers "},
    {value: '323010', label: "323 010 - Développement Umano, Phase 2"},
    {value: '323015', label: "323 015 - Développement Boisé Natura, Phase 2"},
    {value: '323018', label: "323 018 - Développement Albert-Rousseau, Phase 2"},
    {value: "323037", label: "323 037 - Réfection boul. St-Maurice"},
    {value: "323038", label: "323 038 - Pavage ponceaux rtes 367, 365, 354 et 363"},
    {value: "323040", label: "323 040 - Planage et rev. bitumineux routes 80410, 226 et 265"},
    {value: "323041", label: "323 041 - Pulvérisation et couche de correction, 8e Avenue Correction de trous - 8e Rue"},
    {value: "323045", label: "323 045 - Pavage de la rue Principale"},
    {value: "323047", label: "323 047 - Pavage - Réfection route 122"},
    {value: "323048", label: "323 048 - Pavage Dédoublement de l'Autoroute-55"},
    {value: "323050", label: "323 050 - Pavage rue Montcalm"},
    {value: "323054", label: "323 054 - Pavage stationnement bâtiment Mercier 40"},
    {value: "323058", label: "323 058 - Pont route 132 à Gentilly"},
    {value: "323060", label: "323 060 - Pavage rues St-Antoine, St-Pierre, Tessier, Brunelle et Paquin"},
    {value: "323061", label: "323 061 - Ponceaux rtes 263 et 265"},
    {value: "323062", label: "323 062 - Pavage - 103, route Denys-Garneau"},
    {value: "323063", label: "323 063 - Pavage Développement Wendake Est - Phase 2"},
    {value: "323064", label: "323 064 - Pavage de la 4e Avenue"},
    {value: "323065", label: "323 065 - Pavage rues Lavoisier, d'Ars & Vendôme"},
    {value: "323067", label: "323 067 - Pavage, Collecteur d'égoût pluvial rue St-Omer"},
    {value: "323070", label: "323 070 - Pavage stationnement La Pérade Ford"},
    {value: "323072", label: "323 072 - Pavage - 68, route St-Denys-Garneau"},
    {value: "323073", label: "323 073 - Pavage rue Genest"},
    {value: "323077", label: "323 077 - Pavage du stationnement & chemin d'accès Maisons des aînés et maisons alternatives (SQI)"},
    {value: "323078", label: "323 078 - Pavage rues Fleury et des Écoles"},
    {value: "323080", label: "323 080 - Pavage - Zone de connexion - Parc Victoria"},
    {value: "323083", label: "323 083 - Pavage des 2e et 3e rues"},
    {value: "323086", label: "323 086 - Lot H Pavage sur Raoul-Duchêne, Georges-E. Ling et sud de l'autoroute 30  (Port de Bécancour)"},
    {value: "323087", label: "323 087 - Centrale Gentilly 2, Réparation de pavage"},
    {value: "323090", label: "323 090 - Pavage rue du Parc Beaudoin"},
    {value: "323091", label: "323 091 - Pavage rte 363, rues Bourque & Dufresne"},
    {value: "795009", label: "795 009 - Banc Drolet"},
    {value: "795012", label: "795 012 - Carrière St-Marc"},
    {value: "795017", label: "795 017 - Sablière Pont-Rouge"},
    {value: "795019", label: "795 019 - Sablière St-Gilbert"},
    {value: "700037", label: "700 037 - Banc Fontaine"},
    {value: "702013", label: "702 013 - Carrière Portneuf"},
    {value: "703014", label: "703 014 - Banc Julien"},
    {value: "705016", label: "705 016 - Banc St-Laurent"},
    {value: "706025", label: "706 025 - Carrière Lessard"},
    {value: "707030", label: "707 030 - Banc Tring-Jonction"},
    {value: "707031", label: "707 031 - Banc Juneau"},
    {value: "708033", label: "708 033 - Banc Serge Masse"},
    {value: "708034", label: "708 034 - Sablière Ste-Christine"},
    {value: "710020", label: "710 020  - Carrière KM 96"},
    {value: "713021", label: "713 021  - Sablière Paquin"},
    {value: "713025", label: "713 025  - Carrière Ste-Agathe"},
    {value: "714050", label: "714 050  - Sablière Rivard"},
    {value: "714051", label: "714 051  - Banc Noël"},
    {value: "715052", label: "715 052  - Carrière Ste-Louise"},
    {value: "716048", label: "716 048  - Sablière St-Apollinaire"},
    {value: "716049", label: "716 049  - Carrière du Golf"},
    {value: "718050", label: "718 050  - Carrière Neuville"},
    {value: "718051", label: "718 051  - Sablière Régie Neuville"},
    {value: "718054", label: "718 054  - Sablière Régie St-Alban"},
    {value: "719008", label: "719 008  - Carrière Deschambault"},
    {value: "720069", label: "720 069  - Carrière Savard"},
    {value: "720077", label: "720 077  - Sablière Guillaume Thibault"},
    {value: "721079", label: "721 079  - Sablière Ste-Madelaine"}
]