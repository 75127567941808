import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { createTheme, responsiveFontSizes, Button, IconButton } from '@mui/material';
import { ThemeContext, ThemeProvider } from '@emotion/react';
import UserList from './list';


let theme = createTheme();
theme = responsiveFontSizes(theme);


function MobileUserList(props) {

  const users = props.users;

  const handleAddUser = () => {
    // Implement your logic to add a user here
    // For example, you can open a modal or navigate to a new user creation page
    console.log('Add button clicked');
  };

  return (
      <div style={{ backgroundColor: '#f0f0f0', minHeight: '100vh', paddingTop: '0.1px' }}>
        <ThemeProvider theme={theme}>
          <Box sx={{ backgroundColor: 'white' }}>
            <UserList users={users}></UserList>
            <div
              style={{
                position: 'fixed',
                bottom: '16px', // Adjust the distance from the bottom as needed
                right: '16px', // Adjust the distance from the right as needed
              }}
            >
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddUser}
            style={{
              borderRadius: '20px'
            }}
          >
            Nouveau
          </Button>
        </div>
          </Box>
        </ThemeProvider>
      </div>
  );
}

export default MobileUserList;