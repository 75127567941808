import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TimesheetList from './list';
import { createTheme, responsiveFontSizes } from '@mui/material';
import { ThemeContext, ThemeProvider } from '@emotion/react';


let theme = createTheme();
theme = responsiveFontSizes(theme);


function MobileTimesheetList(props) {

  const reports = props.reports;

  return (
      <div style={{ backgroundColor: '#f0f0f0', minHeight: '100vh', paddingTop: '0.1px' }}>
        <ThemeProvider theme={theme}>
          <Box sx={{ backgroundColor: 'white' }}>
            <TimesheetList reports={reports}></TimesheetList>
          </Box>
        </ThemeProvider>
      </div>
  );
}

export default MobileTimesheetList;
