import { Typography } from '@mui/material';
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import FileBox from './file';


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

function DropzoneMobile(props) {
    const { report, onDataChange } = props;

    const [selectedFiles, setSelectedFiles] = React.useState([]);

    React.useEffect(() => {
        // Initialize selectedFiles with report.expenses when the component mounts
        setSelectedFiles(report.expenses);
      }, [report.expenses]);

    const handleFileChange = (event) => {
        if (event.target.files[0]) {
            
            const file = event.target.files[0];
            setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, file]);
            const newData = { ...report };
            newData.expenses = [file, ...newData.expenses];
            onDataChange(newData);
        }
    };

    const handleDelete = (indexToRemove) => {
        setSelectedFiles((prevSelectedFiles) =>
            prevSelectedFiles.filter((_, index) => index !== indexToRemove)
        );
        const updatedFiles = selectedFiles.filter((_, index) => index !== indexToRemove);
        const newData = { ...report };
        newData.expenses = updatedFiles;
        onDataChange(newData);
    };

const status = report.status;
  const [access, setAccess] = React.useState(false);

  React.useEffect(() => {
    if (status === 'En cours') {
      setAccess(true);
    } else if (status === 'En traitement') {
      setAccess(false);
    } else if (status === 'Complété') {
      setAccess(false);
    } else if (status === 'Archivé') {
      setAccess(false);
    } else {
      setAccess(false);
    }

  }, [status])
  

  return (
    <Box>
        <Typography sx={{ marginBottom: '10px' }}><b>Factures</b></Typography>
        <div>
            <Box
            sx={{
                width: '100%',
                maxWidth: '100%',
                color: 'white',
            }}
            >
                <Box sx={{ borderRadius: '6px', height: '100%', backgroundColor: '#EDEDED' }}>
                    <Button
                    component="label"
                    sx={{
                        p: 2,
                        border: '1px dashed grey',
                        borderRadius: 1,
                        width: '100%', // Button takes 100% width of its container
                    }}
                    startIcon={<CloudUploadIcon />}
                    disabled={!access}
                    >
                    Téléverser un fichier
                    <VisuallyHiddenInput type="file" onChange={handleFileChange} />
                    </Button>
                </Box>
            </Box>
        </div>
        <Box paddingTop={4}>
            {selectedFiles.map((file, index) => (
                <Box key={index}>
                    <Box key={index} sx={{ width: '100%', marginBottom: '12px', position: 'relative' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FileBox file={file} filename={file.name} size={file.size} idx={index} handleDelete={handleDelete}></FileBox> 
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                ))}
        </Box>
    </Box>
  );
}

export default DropzoneMobile;
